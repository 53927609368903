import React from 'react';

const SignUp1 = React.lazy(() => import('./Demo/Authentication/SignUp/SignUp1'));
const Signin1 = React.lazy(() => import('./Demo/Authentication/SignIn/SignIn1'));

const Login = React.lazy(() => import('./Pages/Login'));
const Callback = React.lazy(() => import('./Pages/Callback'));
const Unauthorized = React.lazy(() => import('./Pages/401'));


const route = [
    { path: '/auth/signup-1', exact: true, name: 'Signup 1', component: SignUp1 },
    { path: '/auth/signin-1', exact: true, name: 'Signin 1', component: Signin1 },
    { path: '/login', exact: true, name: 'Login', component: Login },
    { path: '/callback', exact: true, name: 'Callback', component: Callback },
    { path: '/401', exact: true, name: 'Unauthorized', component: Unauthorized }
];

export default route;
